import React from 'react'
import Marquee from "react-fast-marquee";

interface Logo {
  alt: string
  src: string
  className?: string
}

export const LogoContainer: React.FC<{logo: Logo}> = ({ logo }) => (
  <div className={`h-full mx-4 p-4 py-6 max-h-[125px] max-w-[200px] flex items-center fill-grape`}>
    <img className={`mx-auto ${logo.className}`} src={logo.src} alt={logo.alt}/>
  </div>
)

export const LogoBlock: React.FC<{logos: Logo[]}> = ({logos = []}) => {
  return (
    <Marquee
      gradientColor={[234, 234, 255]}
      pauseOnHover
      style={{overflow: 'hidden'}}
    >
      {logos.map((logo) => (
        <LogoContainer key={logo.src} logo={logo} />
      ))}
    </Marquee>
  )
}
