import React from 'react'
import hero from '../images/calendar-v2-render@2x.png'
import { AsFeaturedIn } from '../components/AsFeaturedIn'
import { IconBackground } from '../components/IconBackground'
import { TrustedBy } from '../components/TrustedBy'
import { Quotes } from '../components/Quotes'
import { CallToAction } from '../components/CallToAction'
import Layout from '../components/mdxLayout'
import { Features } from '../components/features'
import { Prefooter } from '../components/Prefooter'

export default () => {
  return (
    <Layout>
      <>
        <div className="bg-grape">
          <div className="relative overflow-hidden">
            <div className="text-white opacity-10">
              <IconBackground/>
            </div>

            <div className="relative pb-16 sm:pb-18">
              <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 sm:print:mt-16">
                <div className="text-center">
                  <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                    <span data-sal="fade" data-sal-delay="" data-sal-duration="1000" data-sal-easing="ease" className="block">Next generation software</span>
                    <span data-sal="fade" data-sal-delay="300" data-sal-duration="1000" data-sal-easing="ease" className="block text-3xl sm:text-4xl md:text-5xl">for the <span className="text-violet">Spa &amp; Leisure</span> industry</span>
                  </h1>

                  <div data-sal="fade" data-sal-delay="600" data-sal-duration="1000" data-sal-easing="ease">
                    <p className="mt-3 max-w-md mx-auto text-base text-white opacity-50 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                      Spa and leisure management software doesn't need to be complicated.
                    </p>
                  </div>

                  <div data-sal="fade" data-sal-delay="900" data-sal-duration="800" data-sal-easing="ease">
                    <CallToAction/>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative">
              <div className="absolute inset-0 flex flex-col overflow-hidden" aria-hidden="true">
                <div className="flex-1" />
                <div className="flex-1 w-full bg-violet-light relative text-opacity-25 text-violet">
                  <IconBackground/>
                </div>
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 pb-[80px] aspect-[10/6.25]">
                <img
                  data-sal="slide-up" data-sal-delay="1200" data-sal-duration="1000" data-sal-easing="ease"
                  className="bg-white aspect-[10/6.25] relative rounded-lg shadow-2xl"
                  src={hero}
                  alt="App screenshot"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-8 bg-violet-light">
          <div className="max-w-7xl mx-auto">
            <div className="text-center text-sm uppercase font-medium text-grape text-opacity-75 mb-8">Part of the Trybe</div>
            <TrustedBy/>
          </div>
        </div>

        <div className="bg-violet-light relative overflow-hidden">
          <div className="text-violet text-opacity-25 overflow-hidden">
            <IconBackground/>
          </div>
          <div className="max-w-7xl p-4 sm:p-6 lg:p-8 mx-auto space-y-32 z-10 relative">
            <Quotes/>
            <AsFeaturedIn/>
            <Features/>
          </div>
        </div>

        <div className="bg-grape relative overflow-hidden">
          <div className="text-white text-opacity-10">
            <IconBackground/>
          </div>
          <div className="max-w-6xl p-4 sm:p-6 lg:p-8 mx-auto space-y-32 z-10 relative">
            <Prefooter/>
          </div>
        </div>
      </>
    </Layout>
  )
}
