import CustomerBookingsFeature from '../images/SVG/customer-bookings-feature.svg'
import InventoryFeature from '../images/SVG/inventory-management-feature.svg'
import PaymentsFeature from '../images/SVG/online-payments-feature.svg'
import ReportingFeature from '../images/SVG/reporting-analytics-feature.svg'
import MembershipsFeature from '../images/SVG/memberships-feature.svg'
import DigitalIntakeFeature from '../images/SVG/digital-intake-form-feature.svg'
import IntegrationsFeature from '../images/SVG/integrations-feature.svg'
import { FeatureBlock } from '../components/FeatureBlock'
import React from 'react'

export const Features = () => (
  <div className="pb-20 space-y-36">
    <div data-sal="slide-left" data-sal-duration="500" data-sal-easing="ease">
      <FeatureBlock
        title="Link your live availability to your online customer bookings journey"
        content="Increase your revenue by over 50% and capture bookings all around the clock. Cut down on hours spent manually taking and organising bookings over the phone or email by driving guests to book straight through your website."
        img={CustomerBookingsFeature}
      />
    </div>
    <div data-sal="slide-right" data-sal-duration="500" data-sal-easing="ease">
      <FeatureBlock
        reverse
        title="We're fully cloud-based with an Open-API"
        content="Access Trybe from any device, from anywhere in the world and connect it to your other business solutions. Unlimited users all for one fixed monthly price. No hidden costs."
        img={IntegrationsFeature}
      />
    </div>
    <div data-sal="slide-left" data-sal-duration="500" data-sal-easing="ease">
    <FeatureBlock
      title="Store, manage, and maximise your inventory and retail stock"
      content="Bid goodbye to monthly spreadsheets and manual errors. Trybe processes and accelerates your in-house product sales and inventory management."
      img={InventoryFeature}
    />
    </div>
    <div data-sal="slide-right" data-sal-duration="500" data-sal-easing="ease">
    <FeatureBlock
      reverse
      title="Feed your team rota into your live availability"
      content="Manage your team rota within Trybe and watch as this feeds straight into your live availability both for your team adding bookings and your online booking."
      img={CustomerBookingsFeature}
    />
    </div>
    <div data-sal="slide-left" data-sal-duration="500" data-sal-easing="ease">
    <FeatureBlock
      title="Take the stress out of payments"
      content="Stop taking card payments over the phone or email and become instantly PCI-compliant with Trybe pay. Deliver your guests a slick payment experience with a fully PCI-Compliant checkout process. We store customer cards securely and take the stress out of payments and refunds."
      img={PaymentsFeature}
    />
    </div>
    <div data-sal="slide-right" data-sal-duration="500" data-sal-easing="ease">
    <FeatureBlock
      reverse
      title="Business intelligence &amp; analytics"
      content="Get a holistic view of your operation in real-time with instant reporting and analytics. Segment your bookings with labels and drill into your data."
      img={ReportingFeature}
    />
    </div>
    <div data-sal="slide-left" data-sal-duration="500" data-sal-easing="ease">
    <FeatureBlock
      title="Monitor and grow your membership database"
      content="Manage all of your membership database in one central solution. Trybe also has the ability to carry out your membership payments. We even have a members online login portal for your website"
      img={MembershipsFeature}
    />
    </div>
    <div data-sal="slide-right" data-sal-duration="500" data-sal-easing="ease">
    <FeatureBlock
      reverse
      title="Collect and store digital intake forms"
      content="Personalise your digital intake forms and ensure all information is captured before your guests arrive. We'll store sensitive data securely and comply with GDPR."
      img={DigitalIntakeFeature}
    />
    </div>
  </div>
)
