import React from 'react'
import boutique from '../images/featured/boutique.svg'
import spaBusiness from '../images/featured/spa-business.svg'
import europeanSpa from '../images/featured/european-spa.svg'
import professionalBeauty from '../images/featured/professional-beauty.svg'

const featuredIn = [
  {
    src: boutique,
    name: 'Boutique Hotelier',
    url: 'https://www.boutiquehotelier.com/',
  },
  {
    src: spaBusiness,
    name: 'Spa Business',
    url: 'https://www.spabusiness.com/',
  },
  {
    src: europeanSpa,
    name: 'European Spa',
    url: 'https://europeanspamagazine.com/',
  },
  {
    src: professionalBeauty,
    name: 'Professional Beauty',
    url: 'https://professionalbeauty.co.uk',
  },
]

export const AsFeaturedIn = () => (
  <div className="space-y-6 justify-center text-center">
    <div className="text-center text-sm uppercase font-medium text-grape text-opacity-75">As featured in</div>
    <div className="flex space-x-8 items-center justify-center">
      {featuredIn.map(({src, name, url}) => (
        <a key={name} href={url} target="_blank" title={name} className="flex items-center justify-center">
          <img src={src} alt={name} className="w-[120px]" />
        </a>
      ))}
    </div>
  </div>
)
