import React from 'react'

export const FeatureBlock = ({title, content, reverse = false, img}) => (
  <div className={"md:flex md:items-start px-4 " + (reverse ? 'flex-row-reverse' : '')}>
    <div className="flex-1 sm:mx-12 text-center sm:text-left">
      <div className="sm:text-4xl text-3xl font-bold tracking-tight text-grape">{title}</div>
      <div className="text-lg text-grape text-opacity-50 my-6">
        {content}
      </div>
    </div>
    <div className="sm:w-1/2">
      <img
        src={img}
        className="rounded-xl border-8 border-white mx-auto w-full h-full"
      />
    </div>
  </div>
)
