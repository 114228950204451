import React from 'react'
import DorchesterCollection from '../images/partners/dorchester-collection.svg'
import StrawberryHotels from '../images/partners/strawberry.svg'
import IHG from '../images/partners/ihg.svg'
import Shrigley from '../images/partners/shrigley.svg'
import RadissonBlu from '../images/partners/radisson-blu.svg'
import Lowry from '../images/partners/lowry.svg'
import LanelayHall from '../images/partners/lanelay-hall.svg'
import Breedon from '../images/partners/breedon-priory.svg'
import ManorHouse from '../images/partners/manor-house.svg'
import Lifehouse from '../images/partners/lifehouse.svg'
import LodoreFalls from '../images/partners/lodore-falls.svg'
import SpaHotel from '../images/partners/spahotel.svg'
import Careys from '../images/partners/careys.svg'
import Limewood from '../images/partners/limewood.svg'
import Carden from '../images/partners/carden.svg'
import AlexanderHouse from '../images/partners/ah.svg'
import Nici from '../images/partners/nici.svg'
import Inua from '../images/partners/inua.svg'
import Bluestone from '../images/partners/bluestone.svg'
import Kingsley from '../images/partners/kingsley.svg'

import { LogoBlock, LogoContainer } from './LogoBlock'

const trustedBy = [
  {
    alt: 'Dorchester Collection',
    src: DorchesterCollection,
    className: 'w-full',
  },
  {
    alt: 'Radisson Blu',
    src: RadissonBlu,
    className: 'w-full',
  },
  {
    alt: 'Limewood Hotel',
    src: Limewood,
    className: 'w-full',
  },
  {
    alt: 'Strawberry Hotels',
    src: StrawberryHotels,
    className: 'w-full',
  },
  {
    alt: 'IHG Hotels & Resorts',
    src: IHG,
    className: 'w-full',
  },
  {
    alt: 'Carden Park',
    src: Carden,
    className: 'w-full',
  },
  {
    alt: 'The Inua Collection',
    src: Inua,
    className: 'w-full',
  },
  {
    alt: 'Alexander House Hotels & Utopia Spa',
    src: AlexanderHouse,
    className: 'w-full',
  },
  {
    alt: 'The Nici',
    src: Nici,
    className: 'w-full',
  },
  {
    alt: 'Bluestone Wales Resport',
    src: Bluestone,
    className: 'w-full',
  },
  {
    alt: 'The Kingsley',
    src: Kingsley,
    className: 'w-full',
  },
  {
    alt: 'Shrigley Hall Hotel & Spa',
    src: Shrigley,
    className: 'w-full',
  },
  {
    alt: 'The Lowry Hotel',
    src: Lowry,
    className: 'w-full',
  },
  {
    alt: 'Lifehouse Spa & Hotel',
    src: Lifehouse,
    className: 'w-full',
  },
  {
    alt: 'Careys Manor & SenSpa',
    src: Careys,
    className: 'w-full',
  },
  {
    alt: 'Lodore Falls',
    src: LodoreFalls,
    className: 'w-full',
  },
  {
    alt: 'Lanelay Hall',
    src: LanelayHall,
    className: 'w-full',
  },
  {
    alt: 'The Spa at Breedon Priory',
    src: Breedon,
    className: 'w-[120px]'
  },
  {
    alt: 'Manor House Alsagar',
    src: ManorHouse,
    className: 'w-full',
  },
  {
    alt: 'Spa Hotel',
    src: SpaHotel,
    className: 'w-full',
  },
]

export const TrustedBy = () => (
  <>
    <div className="print:hidden">
      <LogoBlock logos={trustedBy} />
    </div>
    <div className="hidden print:flex w-full space-x-6 justify-center items-center">
      <LogoContainer logo={trustedBy[0]} />
      <LogoContainer logo={trustedBy[1]} />
      <LogoContainer logo={trustedBy[2]} />
      <LogoContainer logo={trustedBy[3]} />
      <LogoContainer logo={trustedBy[4]} />
    </div>
  </>
)
