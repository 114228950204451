import React from 'react'
import Shrigley from '../images/partners/shrigley.svg'
import AdventurePark from '../images/partners/adventurepark.svg'

const quotes = [
  {
    text: "Both bookings and revenue have more than <span class='text-violet font-bold'>doubled</span> and time on the phone has decreased, improving team <span class='text-violet font-bold'>efficiency</span> and guest <span class='text-violet font-bold'>satisfaction</span>.",
    name: "Sharon Rowe",
    company: "Shrigley Hall",
    src: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/286787318.jpg?k=a63def5c666372908e1742ee6c6ba8a14bf28a4cac5f2afed491e300290ec067&o=&hp=1',
    logo: Shrigley
  },
  {
    text: "We're now in excess of <span class='text-violet font-bold'>1,500</span> orders after our first month, with client's <span class='text-violet font-bold'>orders rolling in</span> autonomously from our now <span class='text-violet font-bold'>streamlined</span> online <span class='text-violet font-bold'>shop-front</span>.",
    name: "James Baker",
    company: "Adventure Park Snowdonia",
    src: 'https://visitwalesimages.thedms.co.uk/eandapics/MW/vlarge/1295830_1_8.jpg',
    logo: AdventurePark
  },
]

export const Quotes = () => (
  <div>
    <div className="mx-auto grid md:grid-cols-2 gap-12">
      {quotes.map((quote, index) => (
          <figure
            className="max-w-3xl mx-auto text-center"
            key={index}
            data-sal="fade"
            data-sal-delay="500"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <div className="relative z-10 h-full flex flex-col justify-between">
              <blockquote className="bg-white rounded-2xl p-8 md:p-10 relative">
                <p
                  className="font-serif text-xl md:text-2xl font-medium"
                  dangerouslySetInnerHTML={{__html: quote.text}}
                />
              </blockquote>
              <figcaption className="pt-4 text-grape flex text-left items-center space-x-4">
                <svg
                  className="relative -top-7 ml-10 mb-2"
                  width="39"
                  height="28.5"
                  viewBox="0 0 78 57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M77.043 56.5L6.10352e-05 0H64.0859L77.043 56.5Z" fill="#ffffff"/>
                </svg>

                <div className="flex-1">
                  <div className="text-lg font-medium">{quote.name}</div>
                  <div className="text-gray-500">{quote.company}</div>
                </div>
                <div className="">
                  <img
                    src={quote.logo}
                    alt={quote.company}
                    width={100}
                  />
                </div>
              </figcaption>
            </div>
          </figure>
      ))}
    </div>
  </div>
)
